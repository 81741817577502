$primary: #f2d45c;
$primary-light: #ffff8d;
$primary-dark: #bda32a;
$primary-text: #000000;
$secondary: #0000dc;
$secondary-light: #683dff;
$secondary-dark: #0000a9;
$secondary-text: #ffffff;

$transition: 0.25s ease-in-out;

/* LESSONS COLORS */

$priority-lecture: #81D4FA;
$priority-1: #4CAF50;
$priority-1-bg: #000000;
$priority-2: #81C784;
$priority-2-bg: #000000;
$priority-3: #C8E6C9;
$priority-3-bg: #000000;
$priority-4: #FFFDE7;
$priority-4-bg: #000000;
$priority-5: #FFF59D;
$priority-5-bg: #000000;
$priority-6: #FFEE58;
$priority-6-bg: #000000;
$priority-7: #FDD835;
$priority-7-bg: #000000;
$priority-8: #F9A825;
$priority-8-bg: #000000;
$priority-9: #FB8C00;
$priority-9-bg: #000000;
$priority-10: #EF6C00;
$priority-10-bg: #000000;
$priority-11: #EF5350;
$priority-11-bg: #FFFFFF;
$priority-12: #E53935;
$priority-12-bg: #FFFFFF;
$priority-13: #C62828;
$priority-13-bg: #FFFFFF;
$priority-14: #D50000;
$priority-14-bg: #FFFFFF;
$priority-15: #880E4F;
$priority-15-bg: #FFFFFF;
$priority-16: #9E9E9E;
$priority-16-bg: #000000;
